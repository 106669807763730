import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useReactToPrint} from 'react-to-print';

import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import ReportLogo from 'Icon/report-logo.svg';
import ColorPalette from 'Common/constants/ColorPalette';
import MiniCharacteristic from 'Shared/components/HorseProfile/parts/HorseCharacteristics/MiniCharacteristic';
import HorseDataService from 'Horse/services/HorseDataService';
import {Nullable} from 'Common/types';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import Loading from 'Loading/components/Loading';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportGroupElement} from 'OnlineReport/models/shared/IOnlineReportGroupElement';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import OnlineReportsService from 'OnlineReport/services/OnlineReportsService';
import Image from 'Common/components/Image/Image';
import JumpingHorse from '../Ancestry/parts/common/img/jumping-horse.png';
import {maxBreakpoints} from 'Common/constants/Breakpoints';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import PrintSelectionForm from './PrintSelectionForm';
import {IFormValues, initialValue} from '../PrintSelectionForm/validation';
import Disclaimer from './Disclaimer';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import moment from 'moment';
import {FRONTEND_DATE} from 'Common/constants/Date';

const Root = styled.div`
  width: 100%;
  height: 100%;
  font-family: Source Sans Pro;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${maxBreakpoints.md} {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  @media ${maxBreakpoints.md} {
    align-items: center;
  }
`;

const TitleBar = styled.h1`
  color: #7f0203;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  display: flex;
  gap: 4px;
  align-items: center;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
  }
`;

const Title = styled.span`
  text-align: center;
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const SubTitleItem = styled.h3`
  font-size: 14px;
  margin-bottom: 0px;
`;

const Logo = styled(Image)`
  width: 110px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorseProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-left: 10px;
  margin-right: 10px;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
    gap: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

const HorseProfileImageSection = styled.div`
  display: flex;
  padding-left: 10px;
  flex: 0 0 37%;
`;

const DoubleLayerImage = styled(Image)`
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 0 0 #0000, 0 0 #0000, -15px 15px 0px 0px #7f0203;
  @media print {
    -webkit-filter: drop-shadow(-15px 15px 0px #7f0203);
  }
`;

const HorseProfileGeneralSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  flex: 0 0 25%;
`;

const HorseProfileContactSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 32%;
  align-self: baseline;
`;

const HorseField = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  @media ${maxBreakpoints.md} {
    font-size: 18px;
  }
`;

const FieldLabel = styled.div`
  font-weight: 700;
`;

const FieldValue = styled.div`
  font-weight: 400;
`;

const HorizontalLine = styled.hr`
  background-color: #7f0203;
  height: 2px;
  border: none;
  margin: 8px 0;
`;

const HorseCharacteristicSection = styled.div`
  margin-top: 4px;
`;

const EmptyCharacteristicBox = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid #7f0203;
  margin-right: 8px;
`;

const EmptyCharacteristicTitle = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const EmptyCharacteristicButton = styled.div`
  padding: 4px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: #7f0203;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  color: white;
`;

const VariantSummarySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 30px;
`;

const VariantContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  background-color: #7f0203;
  color: white;
  border-radius: 10px;
  padding: 12px 4px;
  text-align: center;
`;

const VariantDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VariantField = styled.div`
  display: flex;
  gap: 3px;
  font-size: 10px;
  flex-basis: 33%;
  margin-bottom: 4px;
  @media ${maxBreakpoints.md} {
    font-size: 14px;
  }
`;

const TableMetricsSection = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
    gap: 2rem;
  }
`;

const TableSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TableTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  @media ${maxBreakpoints.md} {
    font-size: 18px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
`;

const Thead = styled.thead`
  background-color: #7f0203;
  color: white;
  font-size: 12px;
`;

const TBody = styled.tbody``;

const TColumn = styled.td`
  border: 1px solid black;
  padding: 2px 6px;
`;

const TRow = styled.tr`
  height: 16px;
  font-size: 12px;
`;

const THeadColumn = styled.th<{width?: number}>`
  border: 1px solid black;
  width: ${(props) => (props.width ? props.width + '%' : 'initial')};
  font-weight: 500;
  padding: 2px 6px;
`;

const ToolbarButton = styled(IconButton)`
  @media print {
    display: none;
  }
`;

const ToolbarWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const CustomButton = styled(PrimaryButton)`
  padding: 6px;
  height: unset;
  min-height: unset;
  min-width: unset;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  color: white;
  border: 1px solid white;
  @media print {
    display: none;
  }
`;

type AllProps = IOnlineReportExternalProps;

const mapHeightByVariants: Record<GeneType, number> = {
  Inconclusive: 0,
  NotTested: 0,
  XnOrXx: 0,
  Nn: 0,
  Xn: 1,
  Xx: 2,
};

const getOPCustomizeOptions = () => {
  const opCustomizeOptionText = localStorage.getItem('opCustomizeOptions');
  if (opCustomizeOptionText) {
    try {
      const options = JSON.parse(opCustomizeOptionText);
      if (options['rememberChoices']) return options;
    } catch (err) {}
  }
  return null;
};

const isModifiedCustomizeOptions = () => {
  const options = getOPCustomizeOptions();
  return options && JSON.stringify(options).includes('false');
};

const OnePagerDiagnostics = (props: AllProps) => {
  const horseId = Number(props.horseId);
  const [horseProfile, setHorseProfile] = useState<Nullable<IHorseProfile>>(null);
  const [horseReportDetails, setHorseReportDetails] = useState<Nullable<IOnlineReportHorseDetails>>(null);
  const [geneticVariants, setGeneticVariants] = useState<Nullable<IOnlineReportGeneticVariants>>(null);
  const [horsePanels, setHorsePanels] = useState<IHorseBreedPanel[]>([]);
  const [healthVariants, setHealthVariants] = useState<IOnlineReportHealthVariants[]>([]);
  const [coatColors, setCoatColors] = useState<IOnlineReportCoatColor[]>([]);
  const [abilities, setAbilities] = useState<IOnlineReportAbility[]>([]);
  const [isPrintSelectionModalOpen, setIsPrintSelectionModalOpen] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [customizeOptions, setCustomizeOptions] = useState<IFormValues>(() => {
    return getOPCustomizeOptions() || initialValue;
  });
  const [shouldSelectAll, setShouldSelectAll] = useState(() => {
    if (getOPCustomizeOptions()) {
      return false;
    }
    return true;
  });
  const closePrintSelectionModal = useCallback(() => setIsPrintSelectionModalOpen(false), []);
  const reportPageRef = useRef(null);

  useEffect(() => {
    if (props.shouldTriggerPrint) {
      handlePrint();
    }
  }, [props.shouldTriggerPrint]);

  const pdfContent = useCallback(() => {
    return reportPageRef.current;
  }, [reportPageRef.current]);

  const handlePrint = useReactToPrint({
    content: pdfContent,
    documentTitle: 'Etalon Diagnostics',
    pageStyle: `
    @media print {
      body {
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
        -webkit-filter:opacity(1);
        filter:opacity(1);
      }

      td {
        line-height: 1em;
        font-size: .95em;
      }
      @page {
        margin: 20mm;
      }
    }`,
    onAfterPrint: () => {
      setLoading(false);
    },
    onBeforeGetContent: () => {
      setLoading(true);
    },
  });

  const handleCustomize = () => {
    setIsPrintSelectionModalOpen(true);
  };

  useEffect(() => {
    const horseProfilePromise = HorseDataService.getHorse(horseId);
    const horseReportPromise = OnlineReportsService.getHorseDetails(horseId);
    const geneticVariantsPromise = OnlineReportsService.getGeneticVariants(horseId);
    const horsePanelsPromise = OnlineReportsService.getHorseBreedPanel(horseId);
    const healthIssuesPromise = OnlineReportsService.getHealthVariants(horseId);
    const coatColorsPromise = OnlineReportsService.getCoatColor(horseId);
    const abilityPromise = OnlineReportsService.getAbilities(horseId);
    Promise.all([
      horseProfilePromise,
      horseReportPromise,
      geneticVariantsPromise,
      horsePanelsPromise,
      healthIssuesPromise,
      coatColorsPromise,
      abilityPromise,
    ]).then(([profile, report, variants, panels, healthVariants, colors, abilities]) => {
      setHorseProfile(profile);
      setHorseReportDetails(report);
      setGeneticVariants(variants);
      setHorsePanels(panels);
      setHealthVariants(healthVariants);
      setCoatColors(colors);
      setAbilities(abilities);
      setLoading(false);
    });
  }, []);

  const getCharacteristics = () => {
    if (!horseProfile) return [];
    const values = ['None', 'Low', 'Medium', 'High'];
    return horseProfile.abilities.map((ability) => ({
      label: ability.type + ':',
      value: values.indexOf(ability.state),
      color: ColorPalette.green1,
      name: ability.name,
    }));
  };

  const calculateAge = () => {
    if (!horseProfile?.dateOfBirth) return 'N/A';
    const date = moment(horseProfile.dateOfBirth, FRONTEND_DATE);
    return `${date.month() + 1}/${date.date()}/${date.year()}`;
  };

  const getColors = () => {
    if (!geneticVariants) return [];
    const result = geneticVariants.results.find((item) => item.name === 'Color');
    if (!result) return [];
    const colors = result.genotypes.map((geno) => geno.alleleNames);
    return colors;
  };

  const getAQHAFields = () => {
    const aqhaPanel = horsePanels.find((panel) => panel.name === 'AQHA');
    if (!aqhaPanel) return [];
    return aqhaPanel.traits;
  };

  const getVariantDetectText = (variant: IOnlineReportGroupElement) => {
    const variantsCount = variant.traits.reduce((acc, current) => acc + mapHeightByVariants[current.state], 0);
    switch (variantsCount) {
      case 0:
        return 'No variant detected';
      case 1:
        return '1 variant detected';
    }
    return `${variantsCount} variants detected`;
  };

  const getPerformanceVariantInfo = (variant: IOnlineReportGroupElement) => {
    if (variant.traits.length !== 1 || variant.isAggregated) return null;
    const {geneName} = variant.traits[0];
    const variantsText = getVariantDetectText(variant);
    return {
      id: variant.id,
      trait: geneName,
      genoType: variant.name,
      notes: `${variantsText} - ${variant.affectionTypeResponse?.name}`,
    };
  };

  const getCoatVariantInfo = (variant: IOnlineReportGroupElement) => {
    if (String(variant.state) === GeneType.NotTested) return null;
    const variantsText = getVariantDetectText(variant);
    const segments = variant.name.split(' - ');
    const trait = segments.length === 1 ? segments[0] : segments.slice(0, segments.length - 1).join(' - ');
    const genoType = segments.length === 1 ? '' : segments[segments.length - 1];
    return {
      id: variant.id,
      trait,
      genoType,
      notes: `${variantsText} - ${variant.affectionTypeResponse?.name}`,
    };
  };

  const getHealthResults = () => {
    const results = [];
    const filters = customizeOptions.advancedHealthIssues.traits;
    for (const variant of healthVariants) {
      for (const issue of variant.healthIssues!) {
        const variant = getCoatVariantInfo(issue);
        if (variant && (shouldSelectAll || filters[variant.id]?.isVisible)) results.push(variant);
      }
    }
    return results;
  };

  const getCoatColorResults = () => {
    const results = [];
    const filters = customizeOptions.partialColors.traits;
    for (const coat of coatColors) {
      for (const color of coat.partialColors) {
        const variant = getCoatVariantInfo(color);
        if (variant && (shouldSelectAll || filters[variant.id]?.isVisible)) results.push(variant);
      }
    }
    return results;
  };

  const getPerformanceVariants = () => {
    const results = [];
    const filters = customizeOptions.advancedAbilities.traits;
    for (const performance of abilities) {
      for (const ability of performance.abilities) {
        const variant = getPerformanceVariantInfo(ability);
        if (variant && (shouldSelectAll || filters[variant.id]?.isVisible)) results.push(variant);
      }
    }
    return results;
  };

  const shouldUpgradePerformanceVariants = () => {
    let numOfVariants = 0;
    for (const performance of abilities) {
      numOfVariants += performance.abilities.length;
    }
    return numOfVariants === 0;
  };

  const onSubmitPrintSelectionForm = async (fields: IFormValues) => {
    setCustomizeOptions(fields);
    closePrintSelectionModal();
    setShouldSelectAll(false);
    if (fields.rememberChoices) localStorage.setItem('opCustomizeOptions', JSON.stringify(fields));
    else localStorage.removeItem('opCustomizeOptions');
  };

  const performanceVariants = getPerformanceVariants();
  const characteristics = getCharacteristics();

  return (
    <Root ref={reportPageRef}>
      {isLoading && <Loading />}
      <Header>
        <HeaderLeft>
          <TitleBar className="font-heading">
            <Title>One Page Diagnostics Report</Title>
            <ToolbarWrapper>
              <ToolbarButton
                onClick={handlePrint}
                name={IconName.ArrowDownload}
                color={Theme.color.primary}
                hoverColor={ColorPalette.red9}
                size={24}
                stroke={false}
                fill={true}
              />
              <CustomButton onClick={handleCustomize} theme={''}>
                Customize
              </CustomButton>
            </ToolbarWrapper>
          </TitleBar>
          <SubTitle>
            <SubTitleItem>EtalonDX.com</SubTitleItem>
            <SubTitleItem>LabID: {horseReportDetails?.labId}</SubTitleItem>
          </SubTitle>
        </HeaderLeft>
        <HeaderRight>
          <Logo src={ReportLogo} />
        </HeaderRight>
      </Header>
      <HorizontalLine />
      <MainContent>
        <HorseProfile>
          <HorseProfileImageSection>
            <DoubleLayerImage src={horseProfile?.avatar?.url} alt="Horse avatar" placeholder={JumpingHorse} />
          </HorseProfileImageSection>
          <HorseProfileGeneralSection>
            <HorseField>
              <FieldLabel>Horse:</FieldLabel>
              <FieldValue>{horseProfile?.name}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Breed:</FieldLabel>
              <FieldValue>{horseReportDetails?.breeds.join(', ')}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Date of Birth:</FieldLabel>
              <FieldValue>{calculateAge() || 'N/A'}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Sex:</FieldLabel>
              <FieldValue>{horseReportDetails?.sex}</FieldValue>
            </HorseField>
            <HorseCharacteristicSection>
              {characteristics.map(({label, value, color, name}, i) => (
                <MiniCharacteristic
                  label={label}
                  title={name || 'No results'}
                  color={color}
                  value={value}
                  max={3}
                  isEmpty={!value}
                />
              ))}
              {!characteristics.length && (
                <EmptyCharacteristicBox>
                  <EmptyCharacteristicTitle>
                    Upgrade available for Performance Variants (Temperament, Speed, Gait)
                  </EmptyCharacteristicTitle>
                  <EmptyCharacteristicButton>UPGRADE NOW</EmptyCharacteristicButton>
                </EmptyCharacteristicBox>
              )}
            </HorseCharacteristicSection>
          </HorseProfileGeneralSection>
          <HorseProfileContactSection>
            <HorseField>
              <FieldLabel>Owner:</FieldLabel>
              <FieldValue>{horseReportDetails?.ownerName}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Phone:</FieldLabel>
              <FieldValue>{horseReportDetails?.phone}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Email:</FieldLabel>
              <FieldValue style={{wordBreak: 'break-all'}}>{horseReportDetails?.email}</FieldValue>
            </HorseField>
            {customizeOptions.summary.variantSummary.isVisible && (
              <VariantSummarySection>
                <FieldLabel>Variant Summary</FieldLabel>
                <VariantContent>{getColors().join(', ')}</VariantContent>
                <VariantDetails>
                  {getAQHAFields().map((field) => (
                    <VariantField key={field.geneName}>
                      <FieldLabel>{field.geneName}:</FieldLabel>
                      <FieldValue>{field.alleleNames}</FieldValue>
                    </VariantField>
                  ))}
                </VariantDetails>
              </VariantSummarySection>
            )}
          </HorseProfileContactSection>
        </HorseProfile>
        <TableMetricsSection>
          {(customizeOptions.summary.abilities.isVisible || customizeOptions.summary.coatColors.isVisible) && (
            <TableSectionItem>
              {customizeOptions.summary.abilities.isVisible && (
                <TableWrapper>
                  <TableTitle>Performance Variants</TableTitle>
                  <Table>
                    <Thead>
                      <TRow>
                        <THeadColumn width={30}>Trait</THeadColumn>
                        <THeadColumn width={20}>Genotype</THeadColumn>
                        <THeadColumn width={50}>Notes</THeadColumn>
                      </TRow>
                    </Thead>
                    <TBody>
                      {performanceVariants.map((variant) => (
                        <TRow>
                          <TColumn>{variant.trait}</TColumn>
                          <TColumn>{variant.genoType}</TColumn>
                          <TColumn>
                            <FieldLabel>{variant.notes}</FieldLabel>
                          </TColumn>
                        </TRow>
                      ))}
                      {shouldUpgradePerformanceVariants() && (
                        <TRow>
                          <TColumn rowSpan={1} colSpan={3} style={{textAlign: 'center', fontWeight: 700}}>
                            Upgrade now to access Performance results (Temperament, Speed, Gait)
                          </TColumn>
                        </TRow>
                      )}
                    </TBody>
                  </Table>
                </TableWrapper>
              )}
              {customizeOptions.summary.coatColors.isVisible && (
                <TableWrapper>
                  <TableTitle>Coat Color Results</TableTitle>
                  <Table>
                    <Thead>
                      <TRow>
                        <THeadColumn width={30}>Trait</THeadColumn>
                        <THeadColumn width={20}>Genotype</THeadColumn>
                        <THeadColumn width={50}>Notes</THeadColumn>
                      </TRow>
                    </Thead>
                    <TBody>
                      {getCoatColorResults().map((coatResult) => (
                        <TRow key={coatResult.id}>
                          <TColumn width={5}>{coatResult.trait}</TColumn>
                          <TColumn>{coatResult.genoType}</TColumn>
                          <TColumn>
                            <FieldLabel>{coatResult.notes}</FieldLabel>
                          </TColumn>
                        </TRow>
                      ))}
                    </TBody>
                  </Table>
                </TableWrapper>
              )}
            </TableSectionItem>
          )}
          {customizeOptions.summary.healthVariants.isVisible && (
            <TableSectionItem>
              <TableWrapper>
                <TableTitle>Health Results</TableTitle>
                <Table>
                  <Thead>
                    <TRow>
                      <THeadColumn width={30}>Trait</THeadColumn>
                      <THeadColumn width={20}>Genotype</THeadColumn>
                      <THeadColumn width={50}>Notes</THeadColumn>
                    </TRow>
                  </Thead>
                  <TBody>
                    {getHealthResults().map((healthResult) => (
                      <TRow key={healthResult.id}>
                        <TColumn>{healthResult.trait}</TColumn>
                        <TColumn>{healthResult.genoType}</TColumn>
                        <TColumn>
                          <FieldLabel>{healthResult.notes}</FieldLabel>
                        </TColumn>
                      </TRow>
                    ))}
                  </TBody>
                </Table>
              </TableWrapper>
            </TableSectionItem>
          )}
        </TableMetricsSection>
        {isModifiedCustomizeOptions() && <Disclaimer />}
      </MainContent>
      <ModalWindow isOpen={isPrintSelectionModalOpen} onClose={closePrintSelectionModal}>
        <PrintSelectionForm
          shouldSelectAll={shouldSelectAll}
          initialValue={customizeOptions}
          horseId={+horseId}
          reportType={props.reportType}
          onSubmit={onSubmitPrintSelectionForm}
        />
      </ModalWindow>
    </Root>
  );
};

export default OnePagerDiagnostics;
