import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {
  IActivationCodeCreateRequest,
  IActivationCodeProductDetails,
  IActivationCodeRequest,
} from 'Admin/AdminDashboard/components/ActivationCodes/ActivationCodeForm/validation';
import {AnimalType} from 'Common/constants/AnimalType';

export function convertActivationCodeToClient(activationCode: IActivationCode): IActivationCode {
  return {
    ...activationCode,
  };
}

export function aggregateProductIdAndAmounts(
  productId: string,
  animalType: AnimalType,
  amount: number
): IActivationCodeProductDetails {
  return {
    productId,
    animalType,
    amount,
  };
}

export function convertActivationCodeToActivationCodeCreateRequest(
  activationCode: IActivationCodeRequest
): IActivationCodeCreateRequest {
  return {
    productsDetails: [
      aggregateProductIdAndAmounts(activationCode.productId, activationCode.animalType, activationCode.amount ?? 0),
    ],
    description: activationCode.description,
    source: 'Edx',
  };
}

export function convertActivationCodeToRequest(activationCode: IActivationCodeRequest): IActivationCodeRequest {
  return {
    ...activationCode,
  };
}
